import React, { useContext, useEffect, useRef, useState } from 'react';
import Context from '../../context/Context';
import { fetchForm, fetchFormElements } from '../../context/forms/FormsAction';
import { useParams } from 'react-router-dom';
import { URL } from "../../service/Service";
import Element from "../../components/clientFormElements/Element"
import PriceElement from "../../components/clientFormElements/PriceElement"
import Spinner from '../../components/Spinner';
import euforms from "../../images/euforms.png"
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import Header from '../../components/TitleAndSubtitle';
import { CreditCardIcon, DocumentTextIcon, ShoppingBagIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import CheckoutArea from '../../components/CheckoutArea';

const FormReader = () => {
    const { idform } = useParams()
    const { state, dispatch } = useContext(Context);
    const { loading, data } = state.formelements;
    const page_label = state.form.data.name;
    const page_logo = state.form.data.logo;
    const info = state.form.data.info;

    const api = state.form.data.apiKey;
    const paymentOptions = state.form.data.paymentOptions;
    const latitude = state.form.data.latitude;
    const longitude = state.form.data.longitude;
    const [formElements, setFormElements] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [formElementsWithNumber, setFormElementsWithNumber] = useState([]);
    const [areRequiredFieldsFilled, setAreRequiredFieldsFilled] = useState(false);
    const mapRef = useRef(null);
    const [fixedValue, setFixedValue] = useState(0);
    const [itemPurchases, setItemPurchases] = useState({});
    const [regexMessages, setRegexMessages] = useState([]);
    const [answers, setAnswers] = useState({});
    const [maxQuantityOption, setMaxQuantityOption] = useState(state.form.data.maxQuantityOption);
    const [maxQuantityPerElement, setMaxQuantityPerElement] = useState(state.form.data.maxQuantityPerElement);
    const MAX_LIMIT = 9999;
    const [formStock, setStock] = useState(state.form.data.stock);
    const [stockOption, setStockOption] = useState(state.form.data.stockOption);

    //  const [tempMaxQuantityOfElement, setTempMaxQuantityOfElement] = useState(0);


    const [totalQuantity, setTotalQuantity] = useState(0);
    const [warningCheckoutMessage, setWarningCheckoutMessage] = useState('');
    const [canProceedToCheckout, setCanProceedToCheckout] = useState(false);




    const options = [];
    for (let i = 0; i <= 10; i++) {
        options.push(<option key={i} value={i}>{i}</option>);
    }


    // const paymentImages = {
    //     "Cartão de Crédito": "https://eupago.pt/images/tarifario-meios-12.webp",
    //     "Multibanco": "https://eupago.pt/images/tarifario-meios-08.webp",
    //     "Mb Way": "https://eupago.pt/images/tarifario-meios-01.webp",
    //     "Edenred": "https://eupago.pt/images/tarifario-meios-02.webp",
    //     "Paysafe Card": "https://eupago.pt/images/tarifario-meios-03.webp",
    //     "Paysafe Cash": "https://eupago.pt/images/tarifario-meios-04.webp",
    //     "Débitos Diretos": "https://eupago.pt/images/tarifario-meios-05.webp",
    //     "Payshop": "https://eupago.pt/images/tarifario-meios-06.webp",
    //     "Przelewy24": "https://eupago.pt/images/tarifario-meios-07.webp",
    //     "Cofidis Pay": "https://eupago.pt/images/tarifario-meios-11.webp",
    //     "Santander Consumer": "https://eupago.pt/images/tarifario-meios-13.webp",
    //     "Bizum": "https://eupago.pt/images/tarifario-meios-14.webp",
    // }

    function getRegexString(regexType) {
        switch (regexType) {
            case "NUMBER":
                return /^\d+$/;
            case "TEXT":
                return /^[a-zA-Z0-9çáàâãäéèêëíìîïóòôõöúùûüñÇÁÀÂÃÄÉÈÊËÍÌÎÏÓÒÔÕÖÚÙÛÜÑ\s.,'~`´"\-!@#ªº$«»%^&*()|_+=<>?/]*$/;
            case "NIF":
                return /^[a-zA-Z0-9]{1,20}$/;
            case "NIFPT":
                return /^\d{9}$/;
            case "EMAIL":
                return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            case "PHONE":
                return /^(?:\+|00)[1-9][0-9]{0,3}[1-9][0-9]{7,9}$/;
            case "URL":
                return /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;
            case "EMPTY":
                return /.*/;
            default:
                return /.*/;
        }
    }


    const handleInputChange = (label, value) => {
        setAnswers((prevState) => ({ ...prevState, [label]: value }));

        setFormElements((prevState) => {
            const updatedFormElements = prevState.map((element) => {
                if (element.label === label) {
                    if (element.element_type === 'price') {
                        return { ...element, number: value };
                    } else {
                        return { ...element, element_value: value };
                    }
                }
                return element;
            });

            const areAllRequiredFieldsFilled = updatedFormElements.every(
                (element) => !element.required || element.element_value !== ""
            );

            setAreRequiredFieldsFilled(areAllRequiredFieldsFilled);

            const element = updatedFormElements.find(el => el.label === label);
            if (element && (element.element_type === 'input')) {
                const regexPattern = getRegexString(element.regex);

                // If the input doesn't match the regex and it's not empty
                if (!regexPattern.test(value) && value !== "") {
                    setRegexMessages((prevMessages) => {
                        const message = `Preencha o campo ${element.label} corretamente`;
                        // Avoid duplicate messages
                        return prevMessages.includes(message) ? prevMessages : [...prevMessages, message];
                    });
                } else {
                    // If the input is valid, remove any existing error message for this element
                    setRegexMessages((prevMessages) => prevMessages.filter(msg => msg !== `Preencha o campo ${element.label} corretamente`));
                }
            }

            return updatedFormElements;
        });
    };


    const DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow
    });
    L.Marker.prototype.options.icon = DefaultIcon;

    useEffect(() => {
        const updatedFormElements = formElements.map(element => {
            const newElement = { ...element };
            if (newElement.value === 0) {
                newElement.number = 0;
            }
            return newElement;
        });
        setFormElementsWithNumber(updatedFormElements);
    }, [formElements]);

    useEffect(() => {
        const url = `${URL}/api/forms/${idform}/formelements`;
        const url_form = `${URL}/api/forms/${idform}`;
        fetchFormElements(url, {}, dispatch);
        fetchForm(url_form, {}, dispatch);
    }, [dispatch, idform]);

    useEffect(() => {
        if (data) {
            setFormElements(Object.values(data));
        }
    }, [data]);

    useEffect(() => {
        let totalValue = 0;
        const purchasedItems = {};

        // Ensure formElements is not undefined before iterating over it
        if (formElements) {
            for (const formElement of formElements) {
                if (formElement.element_type === 'price') {
                    const price = parseFloat(formElement.element_value) || 0;
                    const quantity = parseInt(formElement.number) || 0;
                    totalValue += price * quantity;
                    purchasedItems[formElement.id] = quantity;
                }
            }
        }

        setFixedValue(totalValue);
        setItemPurchases(purchasedItems);
    }, [formElements]);

    useEffect(() => {
        if (mapRef.current !== null) {
            const map = L.map(mapRef.current).setView([latitude, longitude], 13);

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
                maxZoom: 18,
            }).addTo(map);

            L.marker([latitude, longitude]).addTo(map);

            return () => {
                map.remove();
            };
        }
    }, [latitude, longitude, mapRef.current]);

    useEffect(() => {
        let sum = 0;
        for (const element of formElementsWithNumber) {
            const price = parseFloat(element.element_value) || 0;
            const count = parseFloat(element.number) || 0;
            sum += price * count;
        }
        setTotalPrice(sum);
    }, [formElementsWithNumber]);

    useEffect(() => {
        setMaxQuantityOption(state.form.data?.maxQuantityOption ?? '');
        setMaxQuantityPerElement(state.form.data?.maxQuantityPerElement ?? '');
    }, [state.form.data?.maxQuantityOption, state.form.data?.maxQuantityPerElement]);

    useEffect(() => {
        setStockOption(state.form.data?.stockOption ?? '');
        setStock(state.form.data?.stock ?? '')
    }, [state.form.data?.stockOption, state.form.data?.stock]);

    useEffect(() => {
        const areAllRequiredFieldsFilled = formElements.every((element) => {
            if (!element.required) return true;

            switch (element.element_type) {
                case 'checkbox':
                    return element.element_value === true; // Ensure checkbox is checked
                case 'price':
                    // Assuming price fields are handled differently; adjust as needed
                    return element.element_value !== "" && !isNaN(parseFloat(element.element_value));
                default:
                    return element.element_value && element.element_value.trim() !== "";
            }
        });
        setAreRequiredFieldsFilled(areAllRequiredFieldsFilled);
    }, [formElements]);

    const replyFields = [];

    for (const key in answers) {
        if (answers.hasOwnProperty(key)) {
            replyFields.push({
                label: key.toUpperCase(),
                answer: answers[key].toString()
            });
        }
    }

    const formReplies = {
        formID: idform,
        replyFields: replyFields
    };

    /**
     * Handle the change of the selected payment method
     *
     * @param event the payment method selection event
     */
    const handleMethodSelectChange = (event) => {

        const selectedMethod = event.target.value;
        setSelectedOption(selectedMethod);
        let isAllowedToCheckout = true;
        let message = '';

        if (selectedMethod === 'Mb Way' && totalPrice > 99999) {
            message = 'O pagamento com Mb Way não é possível para valores superiores a 99.999€.';
            isAllowedToCheckout = false;
        } else if (selectedMethod === 'Multibanco' && totalPrice > 99999) {
            message = 'O pagamento com Multibanco não é possível para valores superiores a 99.999€.';
            isAllowedToCheckout = false;
        } else if (selectedMethod === 'Cartão de Crédito' && totalPrice > 3999) {
            message = 'O pagamento com Cartão de Crédito não é possível para valores superiores a 3.999€.';
            isAllowedToCheckout = false;
        }
        setWarningCheckoutMessage(message);
        setCanProceedToCheckout(isAllowedToCheckout);
    };


    const handleSelectChange = (index, value) => {
        // Reset the proceed to checkout state to make sure the user selects a valid payment method with the current total price
        handleMethodSelectChange({ target: { value: '' } });
        unselectPaymentMethod();

        setFormElements(prevState => {
            // Clone the current state for modification
            const updatedFormElements = [...prevState];
            const newQuantity = parseInt(value, 10) || 0;
            // Calculate the total quantity excluding the current element
            const totalExcludingCurrent = updatedFormElements.reduce((sum, elem, idx) =>
                sum + (idx === index ? 0 : elem.quantity || 0), 0
            );
            const newTotalQuantity = totalExcludingCurrent + newQuantity;
            // Update quantity for the specific element
            updatedFormElements[index].quantity = newQuantity;
            // Update the total quantity in state
            setTotalQuantity(newTotalQuantity);

            // Handle number display in form element as before
            const newFormElements = [...prevState];
            if (value === '0') {
                newFormElements[index] = { ...newFormElements[index], number: 0 };
            } else {
                newFormElements[index] = { ...newFormElements[index], number: newQuantity };
            }
            return newFormElements;
        });
    };


    if (loading === true) {
        return (
            <Spinner></Spinner>
        )
    }

    // Function to calculate the maximum allowed quantity
    const getMaxAllowed = (formElement, formStock, totalQuantity, maxQuantityPerElement, stockOption, maxQuantityOption) => {
        if (stockOption === 'LIMITED') {
            if (maxQuantityOption === 'UNLIMITED') {
                return Math.min(
                    MAX_LIMIT,
                    formStock - totalQuantity + (formElement.quantity || 0)
                );
            } else if (maxQuantityOption === 'LIMITED') {

                let byStock = formStock - totalQuantity - maxQuantityPerElement + (formElement.quantity || 0);
                return Math.min(
                    MAX_LIMIT,
                    maxQuantityPerElement,
                    byStock > 0 ? maxQuantityPerElement - totalQuantity + (formElement.quantity || 0) : MAX_LIMIT,
                    byStock < 0 ? formStock - totalQuantity + (formElement.quantity || 0) : byStock
                );
            } else if (maxQuantityOption === 'TYPE_LIMITED') {
                return Math.min(
                    MAX_LIMIT,
                    formElement.maxQuantityPerElement,
                    formStock - totalQuantity + (formElement.quantity || 0)
                );
            }
        } else if (stockOption === 'TYPE_LIMITED') {
            if (maxQuantityOption === 'UNLIMITED') {
                return Math.min(
                    MAX_LIMIT,
                    formElement.stock
                );
            } else if (maxQuantityOption === 'LIMITED') {
                return Math.min(
                    MAX_LIMIT,
                    formElement.stock,
                    maxQuantityPerElement - totalQuantity + (formElement.quantity || 0)
                );
            } else if (maxQuantityOption === 'TYPE_LIMITED') {
                return Math.min(
                    MAX_LIMIT,
                    formElement.maxQuantityPerElement,
                    formElement.stock
                );
            }
        } else if (stockOption === 'UNLIMITED') {
            if (maxQuantityOption === 'LIMITED') {
                return Math.min(
                    MAX_LIMIT,
                    maxQuantityPerElement - totalQuantity + (formElement.quantity || 0)
                );
            }
            if (maxQuantityOption === 'TYPE_LIMITED') {
                return Math.min(
                    MAX_LIMIT,
                    formElement.maxQuantityPerElement
                );
            }
        }
        return MAX_LIMIT;
    };

    function unselectPaymentMethod(){
        const selectedRadio = document.querySelector('input[name="paymentMethod"]:checked');
        if (selectedRadio) {
            selectedRadio.checked = false;
        }
    }



    return (
        <div className='overflow-x-hidden'>
            <nav className="flex items-center justify-between flex-wrap bg-green-cl p-6">
                <div className="flex items-center flex-shrink-0 text-white mr-6">
                    <img className="h-8" src={euforms} alt="Logo" />
                </div>
            </nav>
            <div className="p-10 w-screen sm:py-12">
                <div className="relative px-4 py-10 bg-white sm:mx-8 md:mx-0 shadow rounded border sm:p-10">
                    <div className="mx-auto">
                        <div className='flex w-full'>
                            <DocumentTextIcon className='mr-6 w-10 h-10'></DocumentTextIcon>
                            <Header title={page_label} />
                        </div>
                        <hr className="border-b my-4 mb-10" />
                        <div className="flex items-left space-x-5"></div>
                        <form>
                            {formElements && formElements.map((formElement, i) => (
                                <div key={formElement.id}>
                                    <Element i={i} formElement={formElement} formId={idform} onInputChange={handleInputChange} />
                                </div>
                            ))}

                            <hr className="h-px my-8 bg-black border-0 dark:bg-gray-700" />
                            <div className='flex w-full'>
                                <Header title="Carrinho" />
                                <ShoppingBagIcon className='ml-6 w-10 h-10'></ShoppingBagIcon>
                            </div>
                            {formElements && formElements.map((formElement, i) => (
                                <div key={formElement.id} className='flex flex-col md:flex-row justify-between items-center md:items-start'>
                                    <PriceElement i={i} formElement={formElement} formId={idform} />
                                    {formElement.element_type === 'price' && (
                                        <div className="bg-white h-16 p-2 mb-5 rounded-md shadow-md border justify-between w-full md:w-fit flex items-center">
                                            <span className="text-gray-500 mr-2 font-medium text-sm uppercase tracking-wider">Quantidade</span>
                                            <div className="flex items-center">
                                                <button
                                                    type="button" // Prevent button from submitting the form
                                                    onClick={() => {
                                                        const inputField = document.getElementById(`quantity-input-${i}`);
                                                        const inputValue = parseInt(inputField.value, 10) || 0; // Get the current input value
                                                        const newValue = Math.max(0, inputValue - 1); // Decrement by 1, ensuring it doesn't go below 0
                                                        inputField.value = newValue; // Update the input field
                                                        handleSelectChange(i, newValue); // Call your change handler
                                                    }}

                                                    className="bg-green-cl text-white rounded-l-md p-2 w-25 hover:bg-gray-400"
                                                >
                                                    -
                                                </button>
                                                <input
                                                    id={`quantity-input-${i}`} // Unique ID for each input
                                                    type="text" // Use text to have full control over input validation
                                                    onInput={e => {
                                                        const inputValue = e.target.value; // Get the raw input value

                                                        // Check for invalid characters (e, -, --, .)
                                                        if (/[^0-9]/.test(inputValue) || /--/.test(inputValue)) {
                                                            e.target.value = inputValue.replace(/[^0-9]/g, ''); // Remove invalid characters
                                                            return; // Exit early to prevent further processing
                                                        }

                                                        const parsedValue = parseInt(e.target.value, 10); // Parse the input as an integer
                                                        const maxAllowed = getMaxAllowed(formElement, formStock, totalQuantity, maxQuantityPerElement, stockOption, maxQuantityOption);

                                                        // Validate the input value against the maxAllowed value
                                                        if (parsedValue > maxAllowed) {
                                                            e.target.value = maxAllowed; // Update the displayed value to maxAllowed
                                                            handleSelectChange(i, maxAllowed);
                                                        } else {
                                                            handleSelectChange(i, parsedValue); // Update the value if valid
                                                        }
                                                    }}
                                                    className='text-xl border-transparent font-bold focus:border-transparent focus:ring-transparent w-20 md:w-24 text-center'
                                                    placeholder="0"
                                                    onWheel={() => document.activeElement.blur()}
                                                    onBlur={e => {
                                                        const inputValue = e.target.value; // Get the raw input value

                                                        // Reset to 0 if the value is not a valid whole number
                                                        if (!/^\d*$/.test(inputValue)) {
                                                            e.target.value = 0; // Reset to 0
                                                            handleSelectChange(i, 0);
                                                            return; // Exit early
                                                        }

                                                        const parsedValue = parseInt(inputValue, 10); // Parse the input as an integer
                                                        const maxAllowed = getMaxAllowed(formElement, formStock, totalQuantity, maxQuantityPerElement, stockOption, maxQuantityOption);

                                                        // Adjust value if it exceeds maxAllowed on blur
                                                        if (parsedValue > maxAllowed) {
                                                            e.target.value = maxAllowed; // Update the displayed value to maxAllowed
                                                            handleSelectChange(i, maxAllowed);
                                                        } else if (parsedValue < 0) {
                                                            e.target.value = 0; // Reset to 0 if it's less than 0
                                                            handleSelectChange(i, 0);
                                                        }
                                                    }}
                                                    min="0"
                                                />
                                                <button
                                                    type="button" // Prevent button from submitting the form
                                                    onClick={() => {
                                                        const inputField = document.getElementById(`quantity-input-${i}`);
                                                        const inputValue = parseInt(inputField.value, 10) || 0; // Get the current input value
                                                        const maxAllowed = getMaxAllowed(formElement, formStock, totalQuantity, maxQuantityPerElement, stockOption, maxQuantityOption);
                                                        const newValue = Math.min(maxAllowed, inputValue + 1); // Increment by 1, ensuring it doesn't exceed maxAllowed
                                                        inputField.value = newValue; // Update the input field
                                                        handleSelectChange(i, newValue); // Call your change handler
                                                    }}
                                                    className="bg-green-cl text-white rounded-r-md p-2 w-25 hover:bg-gray-400"
                                                >
                                                    +
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}

                            <div className="bg-gray-100 p-4 rounded-lg">
                                <h2 className="text-lg font-medium">Preço Total:</h2>
                                <p className="text-2xl font-bold">{totalPrice.toFixed(2).replace(/\./g, ',')} €</p>
                            </div>
                            {
                                (totalPrice > 0 && !areRequiredFieldsFilled) && (
                                    <div className="fixed bottom-0 right-0 mb-4 mr-4 ">
                                        <div className="mt-10 relative flex flex-col animate-fade-up animate-delay-500 animate-ease-in sm:flex-row sm:items-center border border-gray-500 bg-red-200 shadow rounded-md py-5 pl-6 pr-8 sm:pr-6 animate-slide-up">
                                            <div className="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
                                                <div className="text-orange-500">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                    </svg>
                                                </div>
                                                <div className="text-sm text-gray-600 ml-4">Preencha os campos obrigatórios</div>
                                            </div>
                                        </div>
                                    </div>)
                            }
                            {
                                (totalPrice > 0 && regexMessages.length > 0) && (
                                    <div className="fixed bottom-0 right-0 mb-4 mr-4">
                                        <div className="mt-10 relative flex flex-col animate-fade-up animate-delay-500 animate-ease-in sm:flex-row sm:items-center border border-gray-500 bg-red-200 shadow rounded-md py-5 pl-6 pr-8 sm:pr-6 animate-slide-up">
                                            <div className="flex flex-row items-center border-b sm:border-b-0 w-full sm:w-auto pb-4 sm:pb-0">
                                                <div className="text-orange-500">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                    </svg>
                                                </div>
                                                <div className="text-sm text-gray-600 ml-4">
                                                    {regexMessages.map((message, index) => (
                                                        <p key={index}>{message}</p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            {totalPrice > 0 && areRequiredFieldsFilled && regexMessages.length === 0 && (
                                <div className="mt-10">
                                    <hr className="h-px my-8 bg-black border-0 dark:bg-gray-700" />
                                    <div className='flex w-full'>
                                        <Header title="Pagamento" />
                                        <CreditCardIcon className='ml-6 w-10 h-10'></CreditCardIcon>
                                    </div>
                                    <label htmlFor="default-input" className="block font-bold text-gray-900">Escolha o seu método de pagamento:</label>
                                    <div className="space-y-2">
                                        {paymentOptions.map((option) => (
                                            <div key={option} className="flex items-center">
                                                <input
                                                    type="radio"
                                                    id={option}
                                                    name="paymentMethod"
                                                    value={option}
                                                    onChange={handleMethodSelectChange}
                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 peer checked:bg-green-cl"
                                                />
                                                <label
                                                    htmlFor={option}
                                                    style={{ marginLeft: '10px', fontWeight: 'normal', color: '#333' }}
                                                >
                                                    {option}
                                                </label>
                                            </div>
                                        ))}

                                        {warningCheckoutMessage && (
                                            <div className="flex items-center text-red-500 text-sm mt-2">
                                                <ExclamationTriangleIcon className="w-7 h-7 mr-2"/>
                                                {warningCheckoutMessage}
                                            </div>
                                        )}
                                    </div>

                                    {canProceedToCheckout && (
                                        <CheckoutArea method={selectedOption}
                                                      value={totalPrice}
                                                      formReplies={formReplies}
                                                      fixedValue={fixedValue}
                                                      itemPurchases={itemPurchases}>
                                        </CheckoutArea>
                                    )}
                                </div>
                            )}

                        </form>
                    </div>
                </div>
                {/* </div> */}
                {/* </div> */}
            </div >
            <footer className="flex items-center text-center justify-center bg-green-cl p-4">
                <div className="flex items-center flex-shrink-0 text-white mr-6">
                    <small>© Eupago - Instituição de Pagamento - {new Date().getFullYear()}</small>
                </div>
            </footer>
        </div >
    )
}

export default FormReader;
