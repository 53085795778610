import React, { useState, useEffect } from 'react'
import { URL, URL_FRONT } from '../../service/Service';

const CC = ({ value, formReplies, fixedValue, itemPurchases }) => {
  const [redirect, setRedirect] = useState('');
  const [replyResult, setReplyResult] = useState('');
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [isChecked, setIsChecked] = useState(false); // state variable for checkbox
  const [isSubmitting, setIsSubmitting] = useState(false); // state to track form submission
  const urlFront = `${URL_FRONT}/clientforms/${formReplies.formID}`;
  const urlSuccess = `${URL_FRONT}/clientfoms/success/cc`

  const handleFetch = async () => {
    setIsSubmitting(true); // Disable submit button when fetch starts
    try {
      const body = {
        "value": value,
        "successUrl": urlSuccess,
        "failUrl": window.location.href,
        "backUrl": window.location.href,
        "email": email,
        "fixedValue": fixedValue,
        "itemPurchases": itemPurchases,
        "formID": formReplies.formID,
        "replyFields": formReplies.replyFields,
      };

      const options = {
        method: 'POST',
        headers: { accept: 'application/json', 'content-type': 'application/json' },
        body: JSON.stringify(body),
      };
      const response = await fetch(`${URL}/api/payment/cc`, options);
      const result = await response.text();
      const redirectUrl = JSON.parse(result).urlCc; // parse the response and get the redirectUrl value
      setRedirect(redirectUrl);
    } catch (error) {
      setError(error);
    } finally {
      setIsSubmitting(false); // Re-enable button when fetch completes
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (!isSubmitting) { // Prevent multiple submissions
      await handleFetch();
    }
  };

  useEffect(() => {
    if (redirect) {
      window.location.href = redirect; // Redirect to the received URL
    }
  }, [redirect]);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // update checkbox state
  };

  return (
    <div className="mt-5">
      <label className="block mb-2 text-sm font-bold text-gray-900">Endereço de Email</label>
      <input
        type="email"
        name="email"
        placeholder="Digite o seu endereço de email"
        required
        className="border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        onChange={(event) => setEmail(event.target.value)}
      />
            <div className="mt-10 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 inline-flex w-full p-2.5">
                <input
                    id="verify-checkbox"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 mr-3 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600 checked:bg-green-cl"
                    required
                    onChange={handleCheckboxChange} // add onChange handler for checkbox
                />
                <label htmlFor="verify-checkbox" className="cursor-pointer">
                    Tomei conhecimento que a informação constante deste formulário é guardada e tratada pela Eupago e dou consentimento para tal.
                </label>
            </div>
      <div className="flex justify-end">
        <button
          onClick={handleSubmit}
          disabled={!isChecked || isSubmitting} // Disable if checkbox not checked or form is submitting
          className={`ml-3 w-full sm:w-auto bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded ${(!isChecked || isSubmitting) ? 'cursor-not-allowed opacity-50' : ''}`}
          type="submit"
        >
          {isSubmitting ? 'A Processar...' : 'Finalizar'} {/* Change button text while submitting */}
        </button>
      </div>
    </div>
  )
}

export default CC;
