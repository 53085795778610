import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { URL } from '../../service/Service';

const CashPayment = ({ value, formReplies, fixedValue, itemPurchases }) => {
    const [result, setResult] = useState('');
    const [error, setError] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    // const [email, setEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // New state for submit button
    const [replyID, setReplyID] = useState('');

    const navigate = useNavigate(); // Hook to handle navigation

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isSubmitting) {  // Prevent multiple submissions
            handleFetch();
        }
    };

    const handleFetch = async () => {
        setIsSubmitting(true); // Disable button when starting the fetch
        try {
            const body = {
                value,
                // email,
                fixedValue,
                itemPurchases,
                formID: formReplies.formID,
                replyFields: formReplies.replyFields,
            };

            const options = {
                method: 'POST',
                headers: { accept: 'application/json', 'content-type': 'application/json' },
                body: JSON.stringify(body),
            };

            const response = await fetch(`${URL}/api/payment/cashpayment`, options);
            const result = await response.text();
            // Check if the request was successful
            if (response.ok) {
                const parsedResult = JSON.parse(result);
                setResult(parsedResult);
                // Redirect to the new page and pass the result
                navigate('/clientforms/success/cashpayment', { state: { paymentinfo: parsedResult } });
            } else {
                throw new Error('Ocorreu um erro. Por favor tente novamente');
            }
        } catch (error) {
            setError(error.message || 'Ocorreu um erro. Por favor tente novamente');
        } finally {
            setIsSubmitting(false); // Re-enable the button when fetch is done
        }
    };

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    return (
        <div className="mt-5">
            {/* <label className="block mb-2 text-sm font-bold text-gray-900">Email</label>
            <input
                type="email"
                name="email"
                placeholder="Insira o seu Email"
                required
                className="border border-gray-300 text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                onChange={(event) => setEmail(event.target.value)}
            /> */}

            {error && <p className="text-red-500 mt-2">{error}</p>}

            <div className="text-gray-900 text-sm rounded inline-flex w-full p-2.5">
                <input
                    id="verify-checkbox"
                    type="checkbox"
                    className="w-4 h-4 mr-3 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 checked:bg-green-cl"
                    required
                    onChange={handleCheckboxChange}
                />
                <label htmlFor="verify-checkbox" className="cursor-pointer">
                    Tomei conhecimento que a informação constante deste formulário é guardada e tratada pela Eupago e dou consentimento para tal.
                </label>
            </div>
            
            <div className="flex justify-end">
                <button
                    onClick={handleSubmit}
                    disabled={!isChecked || isSubmitting} // Disable button if unchecked or submitting
                    className={`w-full sm:w-auto bg-green-cl hover:bg-opacity-80 text-white py-2 px-4 rounded ${
                        (!isChecked || isSubmitting) ? 'cursor-not-allowed opacity-50' : ''
                    }`}
                    type="submit"
                >
                    {isSubmitting ? 'A Processar...' : 'Finalizar'} {/* Change button text while submitting */}
                </button>
            </div>
        </div>
    );
};

export default CashPayment;
